// Contact page
import React, { Component } from "react";
import { contactAxlry } from "../../firebase/userManagement";
import Layout from "../../components/Layout";
import styles from "./contact.module.css";

class Contact extends Component {
    constructor() {
        super();
        this.state = {
            newMessage: {},
            rows: 3,
            cols: 70,
            isNewMessage: true
        };

        this.submitNewMessage = this.submitNewMessage.bind(this);
    }

    updateByPropertyName = (propertyName, value) => {
        const newMessage = {
            ...this.state.newMessage,
            [propertyName]: value
        };
        this.setState({ newMessage });
    };

    submitNewMessage = e => {
        e.preventDefault();

        contactAxlry(this.state.newMessage);
        this.setState({ isNewMessage: false });
        return false;
    };

    renderForm = () => {
        if (this.state.isNewMessage) {
            return (
                <div>
                    <h1 className={styles.title}>Contact Us</h1>
                    <form
                        className={styles.contactForm}
                        onSubmit={this.submitNewMessage}
                        id="contactform"
                    >
                        {/* FULL NAME INPUT FIELD */}
                        <input
                            required
                            className={styles.inputField}
                            type="text"
                            placeholder="Name"
                            onChange={event =>
                                this.updateByPropertyName(
                                    "name",
                                    event.target.value
                                )
                            }
                        />

                        {/* EMAIL INPUT FIELD */}
                        <input
                            required
                            className={styles.inputField}
                            type="email"
                            placeholder="Email"
                            onChange={event =>
                                this.updateByPropertyName(
                                    "email",
                                    event.target.value
                                )
                            }
                        />

                        {/* SUBJECT INPUT FIELD */}
                        <input
                            required
                            className={styles.inputField}
                            type="subject"
                            placeholder="Subject"
                            onChange={event =>
                                this.updateByPropertyName(
                                    "subject",
                                    event.target.value
                                )
                            }
                        />

                        {/* MESSAGE TEXT AREA */}
                        <textarea
                            required
                            rows={this.state.rows}
                            cols={this.state.cols}
                            placeholder="Your message..."
                            className={styles.inputField}
                            style={{ resize: "vertical" }}
                            onChange={event =>
                                this.updateByPropertyName(
                                    "message",
                                    event.target.value
                                )
                            }
                        />

                        <button className={styles.button} type="submit">
                            Send
                        </button>
                    </form>
                </div>
            );
        } else {
            return <h2>Message Sent!</h2>;
        }
    };

    render() {
        return (
            <Layout title={"AXLRY - Contact"}>
                <div className={`center-container ${styles.pageContainer}`}>
                    {this.renderForm()}
                </div>
            </Layout>
        );
    }
}

export default Contact;
